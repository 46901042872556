import consentUI from "tartemeringuee/consent-ui";
import consentManager from "tartemeringuee/consent-manager";

import gtmService from "tartemeringuee/services/gtm";
import analyticsGtmConsent from "./analytics-gtm-consent";
import hubspotGtmConsent from "./hubspot-gtm-consent";
import linkedinInsightGtmConsent from "./linkedin-gtm-consent";
import FBPixelGtmConsent from "./fb-pixel-gtm-consent";
import matomoWithCookieGTMConsent from "./matomo-with-cookie-gtm-consent";

window.consentManager = consentManager();

const UI = consentUI( { 'siteName': 'www.orthomax.fr', 'privacyURL': '/page/mentions' } );

const gtm = gtmService( 'GTM-WDWQBWW' );
gtm.init();
gtm.accept();

window.consentManager
  .register( matomoWithCookieGTMConsent() )
  .register( analyticsGtmConsent() )
  .register( hubspotGtmConsent() )
  .register( linkedinInsightGtmConsent() )
  .register( FBPixelGtmConsent() )
  .setUI( UI )
  .launch();

document.addEventListener('DOMContentLoaded', () => {
  document.querySelectorAll('.requireConsentCTA')
    .forEach(($node) => {
      $node.addEventListener('click', () => {
        UI.requireConsent();
      });
    });
});
