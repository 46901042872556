// console.log('hello from app.js');
import '../scss/main.scss';

import URLSearchParams from '@ungap/url-search-params';

import $ from 'jquery';

import './vendors/dotdotdot/jquery.dotdotdot.min';

import './vendors/magnific-popup/jquery.magnific-popup.min';

import './vendors/owl/owl-carousel/owl.carousel.min';

import skrollr from './vendors/skrollr.min';

import { tns } from 'tiny-slider/src/tiny-slider';
import 'tiny-slider/dist/tiny-slider.css';
import 'intersection-observer';

import './modules/tartemering-config';

//we export jquery to the global window scope

// create global $ and jQuery variables,
// see https://symfony.com/doc/current/frontend/encore/legacy-applications.html#accessing-jquery-from-outside-of-webpack-javascript-files
global.$ = global.jQuery = $;

//jQuery function written by Darcy Clarke to animate blocks back to auto height.
//Found on CSS Tricks : http://css-tricks.com/snippets/jquery/animate-heightwidth-to-auto/

/*$.fn.animateAuto = function(prop, speed, callback){
  var elem, height, width;
  return this.each(function(i, el){
    el = jQuery(el);
    elem = el.clone().css({"height":"auto","width":"auto"}).appendTo("body");
    height = elem.css("height");
    width = elem.css("width");
    elem.remove();

    if(prop === "height")
      el.animate({"height":height}, speed, callback);
    else if(prop === "width")
      el.animate({"width":width}, speed, callback);
    else if(prop === "both")
      el.animate({"width":width,"height":height}, speed, callback);
  });
};*/

$(document).ready(function()
{

  /*http://dotdotdot.frebsite.nl/*/
  $('.dotdotdot-wrapper').dotdotdot({
    //	configuration goes here
  });

  initOwlSlider();

  initFeedbacksSlider();

  if(document.querySelector(".application-slider") != null){
    initApplicationSlider();
  }

  initContactForm();

  initParrainagePopup();

  initAidePopup();

  initPopup();

  if (window.matchMedia("(max-width: 979px)").matches) {
    $(".fonctionnalites-container-section-article").click(function(){

      if($(this).hasClass('fonctionnalites-active')){
        $(this).removeClass('fonctionnalites-active');
      } else {
        $('.fonctionnalites-container-section-article').removeClass('fonctionnalites-active');
        $(this).addClass('fonctionnalites-active');
      }
    });
  }
});

function initOwlSlider(){
    $("#owl-demo").owlCarousel({

      autoPlay : 3000,
      navigation : true, // Show next and prev buttons
      slideSpeed : 300,
      paginationSpeed : 400,
      singleItem : true,
      stopOnHover: true

      // "singleItem:true" is a shortcut for:
      // items : 1,
      // itemsDesktop : false,
      // itemsDesktopSmall : false,
      // itemsTablet: false,
      // itemsMobile : false
    });

    let s = skrollr.init({
      forceHeight: false,
      mobileCheck: function(){return false;}
    });
}


function initFeedbacksSlider(){
  let slider = tns({
    container: '.feedbacks-slider',
    //mode: 'gallery',
    center: true,
    items: 1,
    //slideBy: 'page',
    autoplay: true,
    autoplayHoverPause: true,
    autoplayButtonOutput: false,
    nav: false,
    //autoWidth: true,
    controlsContainer: '.feedbacks-slider_controls',
    speed: 500,
  });

  // bind function to event
  slider.events.on('indexChanged', (eventInfo)=>{
    //console.debug(eventInfo.displayIndex, eventInfo.slideCount);
    $('#currentFeedbackIndex').text(eventInfo.displayIndex);
  });
}

function initApplicationSlider(){
  let slider = tns({
    container: '.application-slider',
    //mode: 'gallery',
    items: 1,
    slideBy: 1,
    autoplay: false,
    autoplayHoverPause: true,
    autoplayButtonOutput: false,
    nav: false,
    speed: 500,
    fixedWidth: 300,
    mouseDrag: true,
    controlsContainer: '.application-slider_controls',
    loop: true,
    rewind: false,
    autoplayTimeout: 3000,
    onInit : initSlider
  });

  function initSlider (){
    let isDesktop = window.matchMedia('(min-width: 1024px)').matches;
    if(isDesktop){
      let canScroll = true;

      function wheel(event){
        event.preventDefault()
        if (!canScroll) {
          return;
        }

        setTimeout(() => {
          canScroll = true;
        }, 250);

        let scrollDir = event.deltaY > 1 ? 1 : -1;

        if(scrollDir>0) {
          slider.goTo('next');
        }else{
          slider.goTo('prev');
        }

        canScroll = false;
      }

      document.querySelector(".application-slider").addEventListener("mouseenter", (e)=> {
        window.addEventListener('wheel', wheel, {passive: false})
      });

      document.querySelector(".application-slider").addEventListener("mouseleave", (e)=> {
        window.removeEventListener('wheel', wheel);
      })
    }
    }
}

function initPopup(){
  let urlParams = new URLSearchParams(window.location.search);
  if(urlParams.has('popup')){
    $('.trigger-popup-'+urlParams.get('popup')).magnificPopup('open');
  }
}

function initContactForm(){
  $('.plusDinfos-titre').click(function(){

    let $plusDinfos_form = $('.plusDinfos-form');
    $plusDinfos_form.toggleClass('is-opened');

    /*if ($plusDinfos_form.css('height') == '0px') {
      $plusDinfos_form.animateAuto('height', 200);
    } else {
      $plusDinfos_form.animate({
        height: '0'
      }, 500);
    }*/

  });
}

function initParrainagePopup(){
  let  $triggerPopupParrainage = $('.trigger-popup-parrainage');
  $triggerPopupParrainage.magnificPopup({
    items: {
      src: '#popupParrainage'
    },
    type: 'inline',
    removalDelay: 200,
    mainClass: 'mfp-fade'
  });

  let $popupParrainage = $('#popupParrainage');
  if($popupParrainage.find('.popupContainer-contentContainer').hasClass('form-submited')){
    $triggerPopupParrainage.magnificPopup('open');
  }
}


function initAidePopup(){
  $('.trigger-popup-aide').magnificPopup({
    items: {
      src: '#popupAide'
    },
    type: 'inline',
    removalDelay: 200,
    mainClass: 'mfp-fade'
  });
}


