//import addScript from "../utils/addscript";

export const module = ( more = () => true ) => ({
  'key':     'FBPixelGtmConsent',
  'type':    'ads',
  'name':    'Facebook Pixel',
  'uri':     'https://fr-fr.facebook.com/business/help/www/651294705016616',
  'cookies': [],
  'init':    () => {
    window.dataLayer = window.dataLayer || [];
    more();
  },
  'accept':  () => {
    window.dataLayer.push({
      'event': 'fb_pixel_gdpr_consent'
    })
  },
  'refuse':  () => true,
});

export default module;
