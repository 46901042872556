//import addScript from "../utils/addscript";

export const module = ( more = () => true ) => ({
  'key':     'MatomoWithCookieGtmConsent',
  'type':    'analytic',
  'name':    'Matomo with cookie (privacy by design)',
  'uri':     'https://matomo.org/faq/general/faq_146/',
  'cookies': [],
  'init':    () => {
    window.dataLayer = window.dataLayer || [];
    more();
  },
  'accept':  () => {
    window.dataLayer.push({
      'event': 'matomo_with_cookie_gdpr_consent'
    })
  },
  'refuse':  () => true,
});

export default module;
