//import addScript from "../utils/addscript";

export const module = ( more = () => true ) => ({
  'key':     'linkedinInsightGtmConsent',
  'type':    'ads',
  'name':    'Linkedin Insight',
  'uri':     'https://www.linkedin.com/legal/cookie_policy',
  'cookies': [],
  'init':    () => {
    window.dataLayer = window.dataLayer || [];
    more();
  },
  'accept':  () => {
    window.dataLayer.push({
      'event': 'linkedinInsight_gdpr_consent'
    })
  },
  'refuse':  () => true,
});

export default module;
