//import addScript from "../utils/addscript";

export const module = ( more = () => true ) => ({
  'key':     'hubspotGtmConsent',
  'type':    'analytic',
  'name':    'Hubspot',
  'uri':     'https://legal.hubspot.com/privacy-policy',
  'cookies': [],
  'init':    () => {
    window.dataLayer = window.dataLayer || [];
    more();
  },
  'accept':  () => {
    window.dataLayer.push({
      'event': 'hubspot_gdpr_consent'
    })
  },
  'refuse':  () => true,
});

export default module;
